import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { ColorVar } from '../../../../../styles/variables';

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    '& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: `${ColorVar.darkBlue}`,
      borderWidth: '1px',
    },

    '& .MuiInputBase-input.MuiAutocomplete-input': {
      fontSize: '15px',
      margin: 0,
      padding: 0,
    },

    '& div.MuiOutlinedInput-root': {
      padding: '5px',
      [theme.breakpoints.between(0, 'tablet')]: {
        fontSize: '16px',
        lineHeight: '24px',
      },
      [theme.breakpoints.up('tablet')]: {
        fontSize: '18px',
        lineHeight: '26px',
      },
      borderRadius: '10px',

      '& div.MuiButtonBase-root': {
        padding: '0px',
        borderRadius: '4px',
        background: 'inherit',
        border: '1px solid #a2a5a2',
        color: ColorVar.greyBrown,
        height: '25px',
        margin: '3px',
        fontSize: '16px',
      },
      '& .MuiChip-label': {
        paddingLeft: '4px',
        paddingRight: '10px',
      },
      '& input:focus::placeholder': {
        color: 'transparent',
      },
    },
  },
  listText: {
    color: ColorVar.greyBrown,
  },
  opened: {
    '&.top div.MuiOutlinedInput-root': {
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
    },
    '&.bottom div.MuiOutlinedInput-root': {
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px',
    },
    '& button.MuiAutocomplete-popupIndicatorOpen': {
      color: ColorVar.darkBlue,
    }
  },

  popperWrapper: {
    '& div.MuiAutocomplete-paper': {
      width: '100%',
    },

    '& .MuiPaper-root': {
      boxShadow: '0px 0px 5px 0px #bcbcbc',
    },

    '&.top .MuiPaper-root': {
      borderRadius: '10px 10px 0 0',
    },

    '&.bottom .MuiPaper-root': {
      borderRadius: '0 0 10px 10px',
    },

    '& li.MuiAutocomplete-option': {
      height: '30px',

      '&[aria-selected="true"].Mui-focused, &:hover': {
        backgroundColor: ColorVar.lightGrey,
      },

      '&[aria-selected="true"]': {
        backgroundColor: ColorVar.white,
      },
    },
  }
}));

export const clearIconStyle = {
  fontSize: '16px',
  color: ColorVar.greyBrown,
};
