import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  textWrap: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '890px',
    '& .MuiTypography-body1': {
      textAlign: 'center',
    },

    '& .MuiTypography-h2': {
      textAlign: 'center',
      marginBottom: '20px',
    },
    [theme.breakpoints.between(0, 'tablet')]: {
      paddingLeft: '23.5px',
      paddingRight: '23.5px',
      margin: '0px auto 30px',
    },
    [theme.breakpoints.between('tablet', 'desktop')]: {
      marginTop: '70px',
      marginBottom: '40px',
      paddingRight: '90px',
      paddingLeft: '90px',
    },
    [theme.breakpoints.up('desktop')]: {
      marginBottom: '102px',
    },
  },
  pogImg: {
    margin: '20px',
  },
  mobileGridWrapper: {
    marginBottom: '70px',
  },
  mobileGrid: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '164px',
    minHeight: '86px',
    justifyContent: 'center',
  },
  imagesContainer: {
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.between(0, 'tablet')]: {
      marginBottom: '70px',
      paddingRight: '6%',
      paddingLeft: '6%',
    },
    [theme.breakpoints.between('tablet', 'desktop')]: {
      marginBottom: '90px',
    },
    [theme.breakpoints.up('desktop')]: {
      marginBottom: '100px',
      marginLeft: '6%',
      marginRight: '6%',
    },
  },
  imagesWrapper: {
    [theme.breakpoints.between(0, 'tablet')]: {
      display: 'flex',
      alignItems: 'center',
      minWidth: '164px',
      minHeight: '86px',
      justifyContent: 'center',
    },
    [theme.breakpoints.between('tablet', 'desktop')]: {
      marginBottom: '15px',
      marginRight: '20px',
      marginLeft: '20px',
    },
  },
}));
