import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { ColorVar } from '../../../styles/variables';

export const useStyles = makeStyles((theme: Theme) => ({
  footerWrap: {
    marginTop: '100px',
    minWidth: '375px',
    backgroundColor: ColorVar.lightGrey,
    [theme.breakpoints.between(0, 'tablet')]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    [theme.breakpoints.up('tablet')]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
    },
    padding: '30px 30px 0px',
    '& .MuiTypography-body1': {
      fontSize: '18px',
      lineHeight: '26px',
      [theme.breakpoints.between(0, 'tablet')]: {
        marginBottom: '20px',
        marginTop: '22px',
      },
    },
  },
  linkWrap: {
    [theme.breakpoints.between(0, 'tablet')]: {
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    '& .MuiTypography-body2': {
      color: 'black',
      textDecorationColor: 'black',
      fontSize: '18px',
      lineHeight: '18px',
    },
  },
}));
