import { makeStyles } from '@mui/styles'

import { ColorVar } from '../../../../styles/variables'

export const useStyles = makeStyles({
  wrapper: {
    '& .MuiButton-root': {
      width: '165px',
      height: '33px',
      borderRadius: 10,
      borderColor: ColorVar.borderGrey,
      color: ColorVar.black,
      fontSize: '15px',
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '19px',
      textTransform: 'unset',
      textAlign: 'center'
    },
    '& img': {
      height: '16px',
      marginRight: '10px'
    },
    minWidth: '375px',
    padding: '12px 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  }
})
