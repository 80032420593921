import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { ColorVar } from '../../../styles/variables';

export const useStyles = makeStyles((theme: Theme) => ({
  sectionWrap: {
    backgroundImage: `image-set(
      url("/images/background/headerSectionMobile.jpg") 1x,
      url("/images/background/headerSectionMobile@2x.jpg") 2x)`,
    backgroundSize: 'contain',
    backgroundPosition: '25% 25%',
    minHeight: '518px',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('tablet')]: {
      backgroundSize: 'cover',
      alignItems: 'flex-start',
      height: '651px',
      backgroundImage: `image-set(
      url("/images/background/headerSection.jpg") 1x,
      url("/images/background/headerSection@2x.jpg") 2x)`,
    },
  },
  greyBox: {
    backgroundColor: '#6B7069',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.between(0, 'tablet')]: {
      maxWidth: '420px',
      height: '382px',
      padding: '20px 23px',
      marginTop: '140px',
      marginBottom: '-10px',
    },
    [theme.breakpoints.between('tablet', 'desktop')]: {
      maxWidth: '690px',
      marginTop: '128px',
      padding: '40px',
      marginLeft: '5%',
    },
    [theme.breakpoints.up('desktop')]: {
      maxWidth: '719px',
      marginTop: '128px',
      padding: '50px',
      marginLeft: '5%',
    },
  },
  textWrapper: {
    '& .MuiTypography-body1': {
      fontSize: '20px',
      lineHeight: '28px',
      color: ColorVar.white,
      fontWeight: 400,
      paddingTop: '20px',
      paddingBottom: '30px',
    },
    '& .MuiTypography-h1': {
      color: ColorVar.white,
    },
    [theme.breakpoints.between('tablet', 'desktop')]: {
      borderLeft: '3px solid',
      borderColor: ColorVar.orange,
      height: '270px',
      paddingLeft: '30px',
      '& .MuiTypography-h1': {
        fontSize: '48px',
        lineHeight: '60px',
      },
    },
    [theme.breakpoints.up('desktop')]: {
      borderLeft: '3px solid',
      borderColor: ColorVar.orange,
      height: '287px',
      paddingLeft: '30px',
    },
  },
}));
