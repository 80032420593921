import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { ColorVar } from '../../../../../styles/variables';

export const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    paddingLeft: '4px',
    '& .MuiTypography-root': {
      color: ColorVar.darkGrey,
      [theme.breakpoints.between(0, 'tablet')]: {
        fontSize: '16px',
        lineHeight: '24px',
      },
      [theme.breakpoints.up('tablet')]: {
        fontSize: '18px',
        lineHeight: '26px',
      },
    },
    '& .MuiRadio-root': {
      width: '35px',
      height: '35px',
    },
    '& .MuiRadio-root.Mui-checked': {
      color: ColorVar.darkBlue,
    },
  },
}));
