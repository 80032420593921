import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ColorVar } from '../../../../../styles/variables';

export const useStyles = makeStyles((theme: Theme) => ({
  legendWrap: {
    '& .MuiButton-root': {
      margin: '6px 0px 0px -3px',
    },
    '& .MuiAccordionSummary-root': { padding: '0px 16px 0px 5px', minHeight: 0 },
    '& .MuiAccordionSummary-content.Mui-expanded': { margin: 0 },
    [theme.breakpoints.up('tablet')]: {
      '& .MuiAccordionSummary-root.Mui-expanded': { minHeight: '0px', padding: '10px 15px 5px 5px' },
    },
    [theme.breakpoints.between(0, 'tablet')]: {
      '& .MuiAccordionSummary-root.Mui-expanded': { minHeight: '0px', padding: '10px 13px 5px 5px' },
    },
    '& .MuiAccordionDetails-root': {
      padding: 0,
    },

    backgroundColor: ColorVar.white,
    borderRadius: '5px',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
    border: 'none',
    '& .MuiList-root': {
      paddingTop: 0,
    },
    '& .MuiListItem-root': {
      marginLeft: '10px',
      padding: 0,
      [theme.breakpoints.between(0, 'tablet')]: {
        height: '27px',
      },
      [theme.breakpoints.up('tablet')]: {
        height: '33px',
      },
      '& .MuiListItemIcon-root': { minWidth: 0 },
      '& .MuiSvgIcon-root': {
        width: '15px',
        height: '15px',
      },
      '& .MuiTypography-root': {
        color: ColorVar.darkGrey,
        [theme.breakpoints.between(0, 'tablet')]: {
          fontSize: '16px',
          lineHeight: '24px',
          marginLeft: '5px',
        },
        [theme.breakpoints.up('tablet')]: {
          fontSize: '18px',
          lineHeight: '26px',
          marginLeft: '10px',
        },
      },
    },
  },
  legendHeading: {
    flexDirection: 'row-reverse',
    '& .MuiTypography-body2': {
      textTransform: 'none',
      color: ColorVar.black,
      [theme.breakpoints.between(0, 'tablet')]: {
        fontSize: '16px',
        lineHeight: '18.75px',
      },
      [theme.breakpoints.up('tablet')]: {
        fontSize: '18px',
        lineHeight: '21.09px',
      },
      fontWeight: 500,
    },
    '& .MuiSvgIcon-root': {
      color: ColorVar.black,
      fontSize: '25px',
    },
  },
}));
