import { makeStyles } from '@mui/styles';
import { ColorVar } from '../../../styles/variables';

export const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    color: ColorVar.red,
    padding: '11px',
    '& .MuiSvgIcon-root': {
      marginRight: '10px',
    },
  },
}));
