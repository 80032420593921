import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { ColorVar } from '../../../styles/variables';

export const useStyles = makeStyles((theme: Theme) => ({
  sectionWrap: {
    minWidth: '375px',
    textAlign: 'center',
    [theme.breakpoints.up('tablet')]: {
      margin: '100px auto',
    },
    [theme.breakpoints.between(0, 'tablet')]: {
      margin: '70px auto',
    },
    '& .MuiList-root': {
      [theme.breakpoints.up('tablet')]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
      },
    },

    '& 	.MuiListItem-root': {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      justifyContent: 'center',
      '& h3': {
        margin: '16px 0px',
      },
      '& p': {
        maxWidth: '290px',
      },
      [theme.breakpoints.up('tablet')]: {
        maxWidth: '300px',
      },
      [theme.breakpoints.up(1080)]: {
        padding: 0,
      },
      '& .MuiListItemIcon-root ': {
        backgroundColor: ColorVar.darkBlue,
        width: '100px',
        height: '100px',
        borderRadius: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '44px',
        color: ColorVar.white,
      },
      '& img': {
        width: '45px',
        height: '40px',
      },
    },
  },
  textWrap: {
    margin: 'auto',
    [theme.breakpoints.between(0, 'tablet')]: {
      width: '335px',
      '& .MuiTypography-h2': {
        marginBottom: '20px',
      },
    },
    [theme.breakpoints.up('tablet')]: {
      '& p': {
        maxWidth: '874px',
        margin: '20px auto 56px',
      },
      '& h2': {
        marginTop: '100px',
      },
    },
  },
}));
