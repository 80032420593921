import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { ColorVar } from '../../../../../styles/variables';

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    marginTop: '20px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .MuiTypography-h3': {
      [theme.breakpoints.up('tablet')]: {
        fontSize: '26px',
        lineHeight: '32px',
      },
    },
    '& .MuiButton-outlined': {
      width: '67px',
      height: '35px',
      outline: 'none',
      border: '1px solid',
      borderColor: ColorVar.borderGrey,
      textTransform: 'unset',
      fontSize: '18px',
      color: ColorVar.black,
      [theme.breakpoints.between(0, 'tablet')]: {
        margin: '0px 16px',
      },
    },
    '& .MuiIconButton-root': {
      color: ColorVar.black,
      padding: 0,
    },
  },
}));
