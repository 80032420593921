import { createAction, createReducer } from '@reduxjs/toolkit'
import { WeeklyReportsResponse } from '../api/api';

interface WeeklyReportsState {
  ids: number[],
  entities: {[key:number]: WeeklyReportsResponse}
}

const initialState: WeeklyReportsState = {
  ids: [],
  entities: {}
}

export const setWeeklyReportsDataAction = createAction<WeeklyReportsResponse[]>('weeklyReports/setData')

export const weeklyReportsReducer = createReducer(initialState, (builder) => {
  builder
  .addCase(setWeeklyReportsDataAction, (state, action) => {
    state.ids = action.payload.map(({ id }) => id);
    state.entities = action.payload.reduce((acc, el) => {
      return {
        ...acc,
        [el.id]: el
      }
    }, {});
  })
})
