import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  riskComponentWrap: {
    [theme.breakpoints.between(0, 'tablet')]: {
      marginBottom: '56.5px',
    },
    [theme.breakpoints.up('tablet')]: {
      backgroundImage: `image-set(
      url("/images/background/riskSection.jpg") 1x,
      url("/images/background/riskSection@2x.jpg") 2x)`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      padding: '100px 0px',
    },
    minWidth: '375px',
    '& img': {
      width: '100%',
    },
    '& .MuiList-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '& 	.MuiListItem-root': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.between(0, 'tablet')]: {
        width: '340px',
        padding: '20px',
        marginBottom: '16px',
        '& .MuiTypography-h3,.MuiTypography-body1': {
          textAlign: 'left',
          width: '300px',
        },
      },
      [theme.breakpoints.up('tablet')]: {
        marginBottom: '30px',
        maxWidth: '750px',
        height: '183px',
        backgroundColor: '#FFFFFF',
        boxShadow: ' 0px 2px 8px rgba(0, 0, 0, 0.1)',
        '& 	.MuiTypography-h3': {
          textAlign: 'left',
          width: '100%',
        },
      },
      boxShadow: ' 0px 2px 8px rgba(0, 0, 0, 0.1)',
      borderRadius: '10px',
    },
  },
  //FHB risk parameters
  riskTextWrap: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .MuiTypography-body1': {
      [theme.breakpoints.between(0, 'tablet')]: {
        width: '335px',
        marginBottom: '15px',
      },
      [theme.breakpoints.up('tablet')]: {
        maxWidth: '780px',
        padding: '10px',
      },
    },
    '& .MuiTypography-h2': {
      [theme.breakpoints.between(0, 'tablet')]: {
        margin: '16.5px 0px 10px',
      },
    },
  },
}));
