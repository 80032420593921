import { useTheme } from '@mui/styles';
import { Theme, useMediaQuery } from '@mui/material';

export const useMediaHook = () => {
  const muiTheme = useTheme<Theme>();
  const isMobile = useMediaQuery(muiTheme.breakpoints.between(0, 'tablet'));
  const isTablet = useMediaQuery(muiTheme.breakpoints.between('tablet', 'desktop'));
  const isDesktop = useMediaQuery(muiTheme.breakpoints.up('desktop'));
  return { isMobile, isTablet, isDesktop };
};
