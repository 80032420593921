import React from 'react';
import Container from '@mui/material/Container';

import { HeaderSection } from './header-section';
import { PurposeSection } from './purpose-section';
import { RiskParamsSection } from './risk-section';
import { HowItWorksSection } from './how-it-works-section';
import { PartnersSection } from './funding-partners-section';
import { WeatherSection } from './weather-section';
import FooterComponent from '../../shared/components/footer';

export const Home: React.FC = () => {
  return (
    <Container component={'section'} disableGutters={true}>
      <HeaderSection />
      <PurposeSection />
      <RiskParamsSection />
      <HowItWorksSection />
      <PartnersSection />
      <WeatherSection />
      <footer>
        <FooterComponent />
      </footer>
    </Container>
  );
};
