import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

export const CustomButtonComponent = styled(Link)`
  font-family: Roboto-Medium-500, sans-serif;
  background-color: rgba(242, 169, 0, 1);
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  color: #200800;
  transition: all 150ms ease;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: 12px 15px;
  text-decoration: none;

  &:hover {
    background-color: rgba(242, 169, 0, 0.8);
  }
  &.Mui-focusVisible {
    box-shadow: 0 2px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }
`;
