import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { ColorVar } from '../../../styles/variables';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    '&.MuiContainer-root': {
      padding: '0 57px 70px 70px',
      [theme.breakpoints.between(0, 'tablet')]: {
        padding: '0 10px 70px 10px',
      },
    },
    margin: '0 50px 0 70px',
    [theme.breakpoints.between(0, 'tablet')]: {
      margin: '30px 10px 0 10px',
    },
  },
  mapDetailsTitle: {
    '&.MuiTypography-root': {
      marginTop: 0,
      [theme.breakpoints.between(0, 'desktop')]: {
        marginTop: 20,
      },
    },
  },
  titleWrapper: {
    marginTop: 70,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.between(0, 815)]: {
      marginTop: 50,
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
  },

  mapBox: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 30,
    [theme.breakpoints.between(0, 'desktop')]: {
      flexDirection: 'column',
    },
  },
  mapWrapper: {
    width: '100%',
    height: '100px',
    minHeight: '600px',
    background: '#ccc',
    [theme.breakpoints.between(0, 'desktop')]: {
      minHeight: '70vw',
    },
  },
  mapForScreenWrapper: {
    width: '1000px',
    height: '700px',
    position: 'absolute',
    top: '0',
    left: '-5000px',
  },
  mapDetail: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 30,
    width: '100%',
    [theme.breakpoints.between(0, 'desktop')]: {
      marginLeft: 0,
    },
  },
  detailsBox: {
    marginTop: 35,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.between(0, 'desktop')]: {
      marginTop: 0,
    },
  },
  detailItemBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 80,
    [theme.breakpoints.between(0, 'tablet')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      height: 116,
    },
  },
  iconAndTitle: {
    display: 'flex',
    flexDirection: 'row',
  },
  detailIcon: {
    marginRight: 10,
  },
  detailTitle: {
    color: ColorVar.black,
    '&.MuiTypography-root': {
      fontSize: 18,
    },
  },
  detailDescription: {
    '&.MuiTypography-root': {
      marginTop: 0,
      marginLeft: 10,
      fontSize: 19,
      [theme.breakpoints.between(0, 'tablet')]: {
        marginLeft: 0,
        marginTop: 15,
        fontSize: 18,
      },
    },
  },

  disclaimerBox: {
    marginTop: 50,
    display: 'flex',
    flexDirection: 'column',
  },
  disclaimerText: {
    '&.MuiTypography-root': {
      marginTop: 15,
      color: ColorVar.darkGrey,
    },
  },
}));
