import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      html: {
        height: '100%',
      },
      body: {
        height: '100%',
      },
      '#root': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      },
    }
  })
);
