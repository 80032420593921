import React from 'react';
import { useMediaHook } from '../../hooks/useMediaHook';

interface CommonImageOwnProps {
  alt: string;
}

type CommonImageProps = CommonImageOwnProps & React.ImgHTMLAttributes<HTMLImageElement>;

export const CommonImage: React.FC<CommonImageProps> = ({ alt, ...restAttributes }) => {
  const { isMobile } = useMediaHook();
  const src2x = restAttributes.src?.split('.').join(isMobile ? 'Mobile@2x.' : '@2x.');
  const src3x = restAttributes.src?.split('.').join(isMobile ? 'Mobile@3x.' : '@3x.');
  return <img alt={alt} srcSet={`${src2x} 2x, ${src3x} 3x`} {...restAttributes} />;
};
