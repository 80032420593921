import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { ColorVar } from '../../../styles/variables';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: ColorVar.lightGrey,
    textAlign: 'center',
    padding: '30px 15px',
    marginLeft: '5%',
    marginRight: '5%',
    '& .MuiTypography-h3': {
      paddingTop: '30px',
      paddingBottom: '15px',
    },
    [theme.breakpoints.down('tablet')]: {
      paddingTop: '30px',
    },
    [theme.breakpoints.up('tablet')]: {
      flexDirection: 'row',
      textAlign: 'left',
      padding: '25px',
      marginLeft: '10%',
      marginRight: '10%',
    },
    [theme.breakpoints.up('desktop')]: {
      padding: '30px',
      marginLeft: '160px',
      marginRight: '160px',
    },
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100px',
    width: '100px',
    borderRadius: 100,
    backgroundColor: ColorVar.green,
    [theme.breakpoints.up('tablet')]: {
      paddingLeft: '30px',
      paddingRight: '25px',
    },
  },
  textWrapper: {
    [theme.breakpoints.up('tablet')]: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '30px',
    },
  },
}));
