import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  drawer: {
    position: 'relative',
    width: '100vw',
  },
  menuHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '81px',
    paddingLeft: '15px',
    paddingRight: '15px',
    justifyContent: 'space-between',
  },
  typography: {
    position: 'relative',
    textDecoration: 'none',
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '32px',
    color: '#200800',
  },
  navButton: {
    color: 'black',
    background: 'transparent',
    border: 'none',

    width: '21px',
    minHeight: '24px',
    padding: '0',
    margin: '0',
  },
  menuButtonWrapper: {
    marginLeft: '15px',
  },
}));
