export const ColorVar = {
  black: '#200800',
  darkGrey: '#6B7069',
  transparentBackdrop: 'rgba(94, 90, 90, 0.9)',
  orange: '#F2A901',
  orange2: '#F2A900',
  lightGrey: '#F8F8F8',
  lightGreyV2: '#F9F9F7',
  mediumLightGrey: '#F1F1F1',
  darkBlue: '#385E9D',
  white: '#FFFFFF',
  green: '#00AB8E',
  borderGrey: '#BABFB8',
  red: '#D93636',
  greyBrown: '#6B7069',
  datePickerGray: 'rgba(0, 0, 0, 0.6)',
};
